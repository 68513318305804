.contenedor_360{
    width: 100%;
    height: 80vh;
    padding: 20px 20px 0;
    @media (max-width:1024px) {
        height: 50vh;
    }
    #view{
        width: 100% !important;
        height: 100% !important;
    }
}