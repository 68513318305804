body, *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "SFProDisplay-Menium";
}
body{
  overflow-x: hidden;
}
.image-parallax{
    aspect-ratio: 2/1;
    height: 100%;
}
a{
    text-decoration: none;
}
@media (max-width:550px) {
    p{
        font-size: 15px !important;
    }
    .image-parallax{
        height: 100%;
    }
}
@media all and (orientation:landscape) {
    /* Style adjustments for landscape mode goes here */
}
html{
    scroll-behavior: smooth;
}
@font-face {
    font-family: "renderinc";
    src: url(./Fonts/Renderinc_black.otf);
}
@font-face {
    font-family: "Helvetica";
    src: url(./Fonts/HelveticaNeue.otf);
}
@font-face {
    font-family: "SFProDisplay-Black";
    src: url(./Fonts/sf-pro-display/FontsFree-Net-SFProDisplay-Black.ttf);
}
@font-face {
    font-family: "SFProDisplay-Bold";
    src: url(./Fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF);
}
@font-face {
    font-family: "SFProDisplay-Menium";
    src: url(./Fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF);
}
@font-face {
    font-family: "SFProDisplay-semibold";
    src: url(./Fonts/sf-pro-display/FontsFree-Net-SFProDisplay-Semibold.ttf);
}
.ocultar-error{
    display: none;
}
.error{
    display: flex;
    color: red !important;
    font-size: 12px !important; 
}

.show-enter {
    opacity: 0;
    transform:translateX(100%);
  }
  .show-enter-active {
    opacity: 1;
    transform:translateX(0);
    transition: opacity 5s, transform 2s;
  }
  .show-exit {
    opacity: 1;
    transform:translateX(0);
  }
  .show-exit-active {
    opacity: 0;
    transform:translateX(100%);
    transition: opacity 5s, transform 2s;
  }
  .content {
    display: flex;
    align-content: center;
    justify-content: center;
    position: fixed;
    background-color: black;
    width: 100%;
    height: 100vh;
    z-index: 9999;
  }

  .content img{
    width: 200px;
  }
  
  .hidden{
    height: 0;
    transition: height 0.5s ease;
  }
  .hidden h2{
    display: none;
  }