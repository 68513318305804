.menu-open{
    .menu{
        transform: translateY(0);
        @media (max-width:550px) {
           bottom: 80px;
        }
    }
    nav{
        width: 100%;
        right: 0;
        transition: right 1s ease;
        ul{
        }
    }
    span{
        background-color: transparent !important;
        &::before {
            top: 0 !important;
            transform: rotate(-45deg);
        }
        &::after {
            bottom: 0 !important;
            transform: rotate(45deg);
        }
    }
}
nav{
    position: fixed;
    z-index: 10;
    right: -500px;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.637);
    transition: right 1s ease;
    ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;
        height: 100%;
        width: 400px;
        background-color: black;
        @media (max-width:550px) {
            width: 100%;
        }
        li{
            color: white;
            list-style: none;
            padding: 5px 0;
            font-size: 30px;
            font-family: "SFProDisplay-Bold";
            @media (min-width:1600px) {
                font-size: 40px;
            }
            a{
                color: white;
                font-family: "SFProDisplay-Bold";
                cursor: pointer;
            }
        }
        .icono_r{
            height: 130px;
            @media (min-width:1600px) {
                height: 150px;
            }
            p{
                font-size: 70px;
                font-family: "renderinc";
                color: white;
                opacity: 1;
                @media (min-width:1600px) {
                    font-size: 80px;
                }
                @media (max-width:550px) {
                    font-size: 70px !important;
                }
            }
        }
        .social{
            width: 30%;
            height: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            opacity: 0.5;
            @media (min-width:1600px) {
                height: 150px;
            }
        }
        .idioma-movil{
            width: 110px;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            display: none;
            @media (max-width: 550px) {
                display: flex;
            }
            button{
                background-color: transparent;
                color: white;
                font-family: "SFProDisplay-Black";
                font-size: 18px;
                border: none;
                border-bottom: 2px solid transparent;
                &.activo{
                    border-bottom: 2px solid white;
                }
            }
        }
        p{
            font-size: 12px;
            color: white;
            opacity: 0.5;
        }
    }
}